import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['counter'];

    connect() {
        this.viewCounter = 1;
        this.checkAndAnimateCounters();

        window.addEventListener('scroll', this.scrollHandler.bind(this));
    }

    disconnect() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    scrollHandler() {
        this.checkAndAnimateCounters();
    }

    checkAndAnimateCounters() {
        if (this.hasCounterTarget && this.isOnScreen(this.counterTarget) && this.viewCounter === 1) {
            this.viewCounter++;
            this.counterTargets.forEach((counter) => {
                const initialValue = parseInt(counter.dataset.initialValue, 10) || 0;
                this.animateCounter(counter, initialValue);
            });
        }
    }

    animateCounter(counter, initialValue) {
        let currentValue = initialValue;
        const targetValue = parseInt(counter.textContent, 10);

        const animation = setInterval(() => {
            if (currentValue < targetValue) {
                currentValue++;
                counter.textContent = currentValue;
            } else {
                clearInterval(animation);
            }
        }, 20);
    }

    isOnScreen(element) {
        const elementTop = element.getBoundingClientRect().top;
        const elementBottom = element.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        return elementBottom > 0 && elementTop < viewportHeight;
    }
}